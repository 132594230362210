import { Divider, HStack, Stack, Text, VStack } from 'native-base';
import { TeamMemberCardBase } from 'ui/components/Teams/TeamMemberCard/TeamMemberCardBase';
import TeamMemberList from 'ui/components/Teams/TeamMemberList';
import { colors } from 'ui/theme/colors';

import { ContactInfoItem } from '~/components/ContactInfoItem';
import { Loading } from '~/components/Loading';
import Scaffold from '~/components/Scaffold';
import { useViewTeamActivityApplicationController } from '~/pages/Authenticated/ActivityApplication/ViewTeamActivityApplication/controller';

export const ViewTeamActivityApplication = () => {
  const { isLoading, team, leader, groupedMembers, membersTotal } =
    useViewTeamActivityApplicationController();

  if (isLoading || !team)
    return (
      <Stack w="full" h="full" alignItems="center" justifyContent="center">
        <Loading />
      </Stack>
    );

  return (
    <Scaffold
      title={team?.name}
      isViewTeam
      teamMembersQuantity={membersTotal}
      withBg={false}
    >
      <Stack w="full" mt={10} space={6}>
        <Stack p={6} background="white" borderRadius="3xl" space={5}>
          <VStack space={3}>
            <Text color="gray.600" fontSize="xl" fontWeight={500}>
              Team details
            </Text>
            <Divider />
          </VStack>
          <VStack space={8}>
            <HStack flex={1} justifyContent="space-between">
              <VStack flex={0.5} space={2}>
                <Text fontWeight="medium" color="gray.600">
                  Booker
                </Text>

                {leader && (
                  <TeamMemberCardBase
                    title={leader.name || leader.email || 'Unnamed'}
                    descriptionColor={colors.gray[500]}
                    descriptionWeight={400}
                    description={''}
                    avatarUrl={leader.profilePicture}
                    showAvatar={true}
                  />
                )}
              </VStack>
            </HStack>

            <TeamMemberList
              title="Teammates"
              membersData={groupedMembers?.accepted ?? []}
              emptyMessage="Currently no members in the team."
              showTopBar={false}
            />

            {groupedMembers?.pending && (
              <TeamMemberList
                title="Pending teammates"
                membersData={groupedMembers?.pending ?? []}
                emptyMessage="Currently no pending members in the team."
              />
            )}

            {groupedMembers?.rejected && (
              <TeamMemberList
                title="Declined invitations"
                membersData={groupedMembers?.rejected ?? []}
                emptyMessage="Currently no declined members in the team."
              />
            )}

            <VStack mt={8}>
              <VStack space={3}>
                <Text color="gray.600" fontSize="xl" fontWeight={500}>
                  Contact information
                </Text>
                <Divider />
              </VStack>
              <HStack flex={1} mt={6} justifyContent="space-between">
                <Stack flex={0.5}>
                  <ContactInfoItem
                    infoName="Email address"
                    infoValue={leader?.email || ''}
                    icon="mail"
                  />
                </Stack>
              </HStack>
            </VStack>
          </VStack>
        </Stack>
      </Stack>
    </Scaffold>
  );
};

export default ViewTeamActivityApplication;
