import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Team } from 'ui/types/teams';
import {
  getTeamMembersTotal,
  groupTeamMembersByStatus,
  mapUserProfileToTeamMember,
} from 'ui/utils/teams';

import { useLoadActivityApplication } from '~/hooks/useLoadActivityApplication';
import { useQuery } from '~/hooks/useQuery';

export const useViewTeamActivityApplicationController = () => {
  // -- Providers
  const { id: activityApplicationId } = useParams();

  // -- Hooks
  const { data: activityApplication, isLoading: isLoadingActivityApplication } =
    useLoadActivityApplication(String(activityApplicationId));

  const teamId = activityApplication?.teamSubDocument?._id;

  const { data: team, isLoading: isLoadingTeam } = useQuery<Team>(
    `team/${teamId}`,
    {
      queryOptions: {
        enabled: !!teamId,
      },
      key: `team-${teamId}activity-application-${activityApplicationId}`,
    },
  );

  // -- Handlers
  const groupedMembers = useMemo(() => {
    if (!team) return null;
    return groupTeamMembersByStatus(team);
  }, [team]);

  const leader = team
    ? mapUserProfileToTeamMember(team?.leaderSubDocument)
    : null;

  const membersTotal = team ? getTeamMembersTotal(team) : 0;

  const isLoading = isLoadingActivityApplication || isLoadingTeam;

  return {
    activityApplication,
    groupedMembers,
    membersTotal,
    isLoading,
    team,
    leader,
    isLoadingTeam,
  };
};
